<template>
  <!-- 测评管理 -->
  <div class="communal" ref="communal">
    <!-- 顶部固定 -->
    <div class="top-fixed" ref="topFixed">
      <div class="container">
        <!-- -->
        <el-row :gutter="20" class="p-b-10 font-s14 c66">
          <el-col :span="7">
            测评日期：
          </el-col>
          <el-col :span="4">
            测评问卷：
          </el-col>
          <el-col :span="4">
            测评结果：
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-date-picker class="el-long" type="daterange" range-separator="-" start-placeholder="起" end-placeholder="终" v-model="search.date" value-format="yyyy-MM-dd" :editable="false">
            </el-date-picker>
          </el-col>
          <el-col :span="4">
            <el-select clearable v-model="search.topic" placeholder="请选择测评问卷">
              <el-option v-for="(val,index) in scaleList" :key="index" :label="val.title" :value="val.scale_id"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select clearable v-model="search.result" placeholder="请选择测评结果">
              <el-option v-for="(val,index) in scaleStatus" :key="index" :label="val.title" :value="val.status_id"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-input v-model="search.content" placeholder="请输入关键字查询"></el-input>
          </el-col>
          <el-col :span="5">
            <el-button type="success" plain @click="serach">搜索</el-button>
            <el-button type="success" plain @click="exportData">导出</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="interval"></div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="container" :style="{height:contentHeight+'px'}">
        <template>
          <el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%" :height="contentHeight" @selection-change="selectionChange" :row-class-name="tableRowClassName">
            <el-table-column type="selection" width="60">
            </el-table-column>
            <el-table-column prop="user_name" label="测评人" min-width="120">
            </el-table-column>
            <el-table-column prop="telphone" label="联系方式" min-width="140">
            </el-table-column>
            <el-table-column prop="sex" label="性别" min-width="120">
            </el-table-column>
            <el-table-column prop="age" label="测评年龄" min-width="120">
            </el-table-column>
            <el-table-column prop="scale_title" label="问卷" min-width="240" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="type" label="测评结果" min-width="100">
            </el-table-column>
            <el-table-column prop="scale_type" label="测评方式" min-width="140">
            </el-table-column>
            <el-table-column prop="addtime" label="测评时间" min-width="180">
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click.stop="operation(scope.row.id,scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
    </div>
    <!-- 分页 100-->
    <div class="paging" ref="paging">
      <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" :page-sizes="[10, 50, 100, 2000]" :page-size="psize"
        layout="total, sizes, prev, pager, next, jumper" :total="totals"></el-pagination>
    </div>
    <!-- 弹框  start -->
    <el-dialog :title="scaleTitle" :visible.sync="dialogVisible" width="840px" top="20%">
      <div style="padding:30px 60px 0;max-height:450px;overflow-y:auto" class="font-s14" v-if="dialogTitle!=''">
        <el-row>
          <el-col :span="24">
            <div class="title-all-bg c06 font-s16" style="margin: 0px 0px 0px;">基本信息</div>
          </el-col>
        </el-row>
        <el-row v-if="data.userinfo.length>0">
          <el-col v-for="(item,index) in data.userinfo" :key="index" :span="8" style="padding-top:12px">
            <div>
              <span class="span-title">{{ item.name }}</span>
              <span class="span-text">{{ item.value }} </span>
            </div>
          </el-col>
        </el-row>
        <div class="scale-result">
          <div>
            <div v-for="(item,index) in data.lines" :key="index" class="tab">
              <h5 class="title-all-bg c06 font-s16">{{ item.title }}</h5>
              <div v-if="item.column.length > 0 && item.column!=undefined" class="tabTitle c38c flex-start">
                <div v-for="(itm,index2) in item.column" :key="index2" :style="'width:'+100/item.column.length+'%'">{{ itm }}</div>
              </div>
              <template v-if="item.lines.length > 0 && item.lines!==undefined">
                <div v-for="(itm,index2) in item.lines" :key="index2" class="tabList borderee flex-start">
                  <div v-for="(valindex,index3) in itm" :key="index3" :style="'width:'+100/item.column.length+'%'">{{ valindex.val }}</div>
                </div>
              </template>
            </div>
          </div>
          <!-- 评估说明 -->
          <!-- 评估建议 -->
          <div v-if="dbs.length>0" class="explain bg-ff">
            <div v-for="(item,index) in dbs" :key="index">
              <p v-if="item.title!=='评估说明'" class="p-title">{{ item.title }}：</p>
              <p v-if="item.title==='评估说明'" class="p-title p-star">
                {{ item.title }}：
                <!-- 程度 -->
                <!-- <el-radio-group v-model="degree">
                  <el-radio v-for="(val,index2) in degreeList" :key="index2" :label="val.id">{{ val.title }}</el-radio>
                </el-radio-group>-->
              </p>
              <div v-for="(itm,index2) in item.db" :key="index2" style="padding:0px">
                <div class="font-s28 c99" style="padding:5px 0">{{ itm.title }}</div>
                <span v-if="item.img_flag == false" class="font-s28 c333">
                  <div v-if="item.title!='评估说明' && item.title!='评估建议'" class="p-t-10 p-b-10">{{ itm.state }}</div>
                  <!-- 富文本 -->
                  <div v-if="item.title==='评估说明' || item.title==='评估建议'">
                    {{itm.state}}
                  </div>
                  <!-- 富文本结束 -->
                </span>
                <img v-if="item.img_flag == true" :src="itm.state" style="height:70%;" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 按钮 -->
      <div class="account-footer clearfix">
        <div>
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="printFun">打印</el-button>
        </div>
        <div>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 弹框  end -->

    <!-- 打印 -->
    <div v-show="false">
      <printTestReport ref="print"></printTestReport>
    </div>
  </div>
</template>
<script>
import { ScaleLists } from "../../api/api"; //测评问卷列表
import { ScalerList } from "../../api/api"; //测评列表
import { ScalerInfo } from "../../api/api"; //详情
import { ScaleStatusList } from "../../api/api"; //状态
import { ExportScale } from "../../api/api"; //导出
import printTestReport from "../../components/printTestReport"

export default {
  data() {
    return {
      topHeight: "", //顶部搜索的高度
      mainHeight: "", //获取当前容器的高度
      pagingHeight: "", //获取分页的高度
      contentHeight: 0, //内容的高度
      totals: 0,
      pages: "1",
      psize: 10,
      currentPage: 1, //默认显示页
      search: {
        //搜索
        date: "", //日期
        topic: "", //测评问卷
        result: "", //结果
        content: "", //文本内容
      },
      list: [],
      dialogVisible: false,
      refuseContent: "", //拒绝内容
      scaleList: "", //测评问卷列表
      scaleStatus: "", //测评结果列表
      dialogTitle: "", //测试详情
      scaleTitle: "",
      dbs: [], // 评估建议 、评估说明
      data: "",
      exportDataSel: [], //导出的数据
    };
  },
  created() {
    this.getList();
  },
  components: {
    printTestReport
  },
  mounted() {
    var that = this;
    that.common.Count.pageResize(that);
    window.onresize = function temp() {
      that.common.Count.pageResize(that);
    };
    that.getScaleLists();
    that.getScaleStatusList();
  },
  methods: {
    handleCurrentChange(val) {
      this.pages = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.psize = val;
      this.getList();
    },
    serach() {
      this.getList("搜索");
    },
    getList(operationName) {
      var fromDate = "";
      var toDate = "";
      if (this.search.date != "") {
        fromDate = this.search.date[0];
        toDate = this.search.date[1];
      }
      var that = this;
      var db = {
        from_date: fromDate, //	起始时间
        to_date: toDate, //	结束时间
        scale_id: this.search.topic, //量表id
        type: this.search.result, //结果id
        pages: this.pages,
        pnums: this.psize,
        txt: this.search.content, //搜索内容
      };
      this.$axios.post(ScalerList, db).then(
        (res) => {
          if (res.data.code == 1) {
            if (res.data.data != undefined && res.data.data.length > 0) {
              this.list = res.data.data;
              this.totals = res.data.count;
            } else {
              this.list = [];
              this.totals = res.data.count;
              if (operationName == undefined) {
                this.tableTip = "暂无测评记录";
              } else {
                this.tableTip = "暂无搜索结果";
              }
            }
          } else {
            this.list = [];
            this.tableTip = "暂无测评记录";
          }
        },
        (err) => {
          this.list = [];
          this.tableTip = "暂无测评记录";
        }
      );
    },
    getScaleLists() {
      //测评问卷列表
      this.$axios.post(ScaleLists, {}).then(
        (res) => {
          if (res.data.code == 1) {
            this.scaleList = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        },
        (err) => {
          this.$message.error(err.msg);
        }
      );
    },
    getScaleStatusList() {
      //测评结果列表
      this.$axios.post(ScaleStatusList, {}).then(
        (res) => {
          if (res.data.code == 1) {
            this.scaleStatus = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        },
        (err) => {
          this.$message.error(err.msg);
        }
      );
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.type == "异常") {
        return "freeze";
      }
    },
    operation(id, row) {
      //对数据的操作-按钮   查看
      var db = {
        id: id,
      };
      this.$axios.post(ScalerInfo, db).then(
        // 查看量表
        (res) => {
          if (res.data.code == 1) {
            this.data = res.data.data;
            if (res.data.data.dbs !== undefined) {
              if (res.data.data.dbs.length > 0) {
                this.dbs = res.data.data.dbs;
              }
            }
            this.dialogVisible = true;
            this.scaleTitle = row.scale_title;
            this.dialogTitle = row.scale_title;
          } else {
            this.$message.error(res.data.msg);
          }
        },
        (err) => {
          this.$message.error(err.msg);
        }
      );
    },
    selectionChange(sel) {
      //表格选中获取当前选中行
      this.exportDataSel = sel;
    },
    exportData() {
      //导出
      if (this.exportDataSel.length <= 0) {
        this.$message.info("至少选择一个用户进行导出");
      } else {
        var ids = [];
        for (var i = 0; i < this.exportDataSel.length; i++) {
          ids.push(this.exportDataSel[i].id);
        }
        var db = {
          ids: ids.join(","),
        };
        this.$axios.post(ExportScale, db, { responseType: "arraybuffer" }).then(
          (res) => {
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            const fileName =
              res.headers["content-disposition"].match(/filename=(\S*).xls/)[1];
            const elink = document.createElement("a");
            elink.download = JSON.parse(fileName) + ".xls";
            elink.href = window.URL.createObjectURL(blob);
            elink.click();
            window.URL.revokeObjectURL(elink.href);
          },
          (err) => {}
        );
      }
    },
    printFun() {
      let data = this.data;
      let testDetail = this.$refs.print.testDetail;
      testDetail.print_date = data.print_date;
      testDetail.print_title = this.scaleTitle;
      testDetail.userinfo = data.userinfo;
      testDetail.dbs = data.dbs;
      testDetail.lines = data.lines;
      this.$nextTick(function () {
        setTimeout(() => {
          this.$print(this.$refs.print); // 使用
        }, 1500);
      });
    },
  },
};
</script>

<style lang="scss">
.test-detail {
  max-height: 500px;
  .tag-name {
    width: 10%;
  }
  .span-title {
    color: #999;
    width: 110px;
    text-align: right;
    display: inline-block;
  }
  .span-text {
    margin-left: 10px;
    color: #505050;
  }
  .foot {
    padding-top: 20px;
    border-top: 1px solid #f4f4f4;
  }
}
.scale-result {
  margin-bottom: 20px;
  .c-r {
    text-align: right;
  }
  .p-title {
    color: #25bbdb;
    font-size: 14px;
    font-weight: bold;
  }
  .flex-start {
    display: flex;
    justify-content: flex-start;
  }
  .borderee {
    border-top: 1px #eeeeee solid;
  }
  .c38c {
    color: #25bbdb;
  }
  .title {
    padding: 28px 24px 20px;
  }
  .tabTitle {
    height: 58px;
    line-height: 58px;
  }
  .tabTitle div {
    line-height: 58px;
  }
  .tabTitle :last-child {
    text-align: right;
  }
  .tabList {
    padding: 19px 0;
  }
  .tabList div {
    color: #333333;
  }
  .tabList div:last-child {
    text-align: right;
  }
  .explain div {
    // padding: 10px 0 0px;
  }
  .tab {
    margin-top: 31px;
  }

  // 富文本编辑器样式
  .quill-editor {
    border: 1px solid #ccc;
    .ql-toolbar.ql-snow {
      display: none;
      border: none !important;
    }
    .ql-container.ql-snow {
      border: none !important;
    }
  }
}
.title-all-bg {
  font-weight: bold;
}
.font-s16 {
  font-size: 14px;
}
h5 {
  margin: 10px 0px;
}
</style>
